/* eslint-disable no-useless-escape */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-underscore-dangle */
import { Button, Form, Input, Row, Col, Select, notification, Modal } from 'antd';
import { useAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import moment from 'moment';
import ReactInputMask from 'react-input-mask';
import style from './index.module.css';
import { tenantData } from '../../jotaiStore/tenants';
import { getAllClients } from '../../services/client';
import { addDonorService, driverDetailFromLicenseNumber } from '../../services/donor';
import useStates from '../../utility/us_states.json';
import DateInput, { regexPattern } from '../../components/dateInput/dateInput';

const regexPhone = /^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;
// const hiringStates = [
//   { value: 'North East', stateName: 'North East' },
//   { value: 'South', stateName: 'South' },
//   { value: 'South East', stateName: 'South East' },
//   { value: 'AK', stateName: 'Alaska' },
//   { value: 'IL', stateName: 'Illinois' },
//   { value: 'AL', stateName: 'Alabama' },
//   { value: 'AZ', stateName: 'Arizona' },
//   { value: 'AR', stateName: 'Arkansas' },
//   { value: 'CA', stateName: 'California' },
//   { value: 'CO', stateName: 'Colorado' },
//   { value: 'CT', stateName: 'Connecticut' },
//   { value: 'DE', stateName: 'Delaware' },
//   { value: 'FL', stateName: 'Florida' },
//   { value: 'GA', stateName: 'Georgia' },
//   { value: 'HI', stateName: 'Hawaii' },
//   { value: 'ID', stateName: 'Idaho' },
//   { value: 'IN', stateName: 'Indiana' },
//   { value: 'IA', stateName: 'Iowa' },
//   { value: 'KS', stateName: 'Kansas' },
//   { value: 'KY', stateName: 'Kentucky' },
//   { value: 'ME', stateName: 'Maine' },
//   { value: 'MD', stateName: 'Maryland' },
//   { value: 'MA', stateName: 'Massachusetts' },
//   { value: 'MI', stateName: 'Michigan' },
//   { value: 'MN', stateName: 'Minnesota' },
//   { value: 'MS', stateName: 'Mississippi' },
//   { value: 'MO', stateName: 'Missouri' },
//   { value: 'NE', stateName: 'Nebraska' },
//   { value: 'NH', stateName: 'New Hampshire' },
//   { value: 'NV', stateName: 'Nevada' },
//   { value: 'UT', stateName: 'Utah' },
//   { value: 'OR', stateName: 'Oregon' },
//   { value: 'OH', stateName: 'Ohio' },
//   { value: 'NY', stateName: 'New York' },
//   { value: 'NC', stateName: 'North Carolina' },
//   { value: 'SC', stateName: 'South Carolina' },
//   { value: 'NY', stateName: 'Pennsylvania' },
//   { value: 'RI', stateName: 'Rhode Island' },
//   { value: 'WH', stateName: 'Washington' },
//   { value: 'VA', stateName: 'Virginia' },
//   { value: 'WV', stateName: 'West Virginia' },
//   { value: 'VT', stateName: 'Vermont' },
//   { value: 'WY', stateName: 'Wyoming' },
//   { value: 'SD', stateName: 'South Dakota' },
//   { value: 'ND', stateName: 'North Dakota' },
//   { value: 'OK', stateName: 'Oklahoma' },
//   { value: 'NJ', stateName: 'New Jersey' },
//   { value: 'NM', stateName: 'New Mexico' },
//   { value: 'NH', stateName: 'New Hemisphere' },
//   { value: 'MT', stateName: 'Montana' },
//   { value: 'TN', stateName: 'Tennessee' },
//   { value: 'TX', stateName: 'Texas' },
//   { value: 'WI', stateName: 'Wisconsin' },
//   { value: 'WY', stateName: 'Wyoming' },
// ];

function DonorCheckIn() {
  const [getTenant] = useAtom(tenantData);
  const [form] = Form.useForm();
  const [selectClientType, setSelectClientType] = useState('');
  const [loading, setLoading] = useState(false);
  const [licenseNumber, setLicenseNumber] = useState('');
  const [driverId, setDriverId] = useState('');
  const [issueState, setIssueState] = useState('');
  const [companyData, setCompanyData] = useState([]);
  const [notificationModal, setNotificationModal] = useState(false);
  const getCompanyDetailsFun = () => {
    getAllClients({
      query: {
        limit: 1000,
      },
    }).then((res) => {
      const data = res?.data?.map((i) => {
        return {
          value: i.uuid,
          label: i.motorCarrierName,
          id: i?.uuid,
        };
      });
      setCompanyData(data);
    });
  };
  useEffect(() => {
    getCompanyDetailsFun();
  }, []);

  const getDriverDetails = () => {
    if (licenseNumber && issueState && selectClientType === 'house-client') {
      setLoading(true);
      driverDetailFromLicenseNumber({
        pathParams: {
          licenseNumber,
          issueState,
        },
      })
        .then((res) => {
          setLoading(false);
          if (res?.data?.uuid) {
            setDriverId(res?.data?.uuid);
            form.setFieldsValue({
              firstName: res?.data?.firstName,
              lastName: res?.data?.lastName,
              email: res?.data?.email,
              phoneNumber: res?.data?.phoneNumber,
              dob: res?.data?.dob && moment(res?.data?.dob).format('MM/DD/YYYY'),
              companyId: res?.data?.clientId,
            });
          } else {
            setDriverId('');
          }
        })
        .catch(() => {})
        .finally(() => {
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    if (licenseNumber && issueState && selectClientType === 'house-client') getDriverDetails();
  }, [licenseNumber, issueState, selectClientType]);
  const onChange = () => {};
  const onSearch = () => {};

  const onSubmit = (values) => {
    addDonorService({
      body: {
        ...values,
        clientType: selectClientType,
        ...(driverId && { driverId }),
        driverLicenseState: values?.driverLicenseState,
      },
    })
      .then(() => {
        // message.success(
        //   'Thank you for checking in. Please wait in the lobby and drink some water. We will call you soon!',
        // );
        // notification.success({
        //   message: 'Thank You for check in',
        //   description: `Please drink enough water to produce sufficient required urine sample. If not ready please let the
        //           front desk know you want to wait. Thank you for your visit`,
        //   className: 'custom-class',
        //   duration: 15,
        //   style: {
        //     width: 600,
        //     fontFamily: 'Helvetica Neue, sans-serif',
        //     fontSize: 20,
        //     fontWeight: 500,
        //   },
        //   onClose: () => {},
        // });
        setNotificationModal(true);
        form.resetFields();
        setSelectClientType('');
        setLicenseNumber('');
        setDriverId('');
      })
      .catch((error) => {
        notification.error({
          message: error?.data?.error?.message,
        });
      });
  };
  useEffect(() => {
    if (notificationModal) {
      setTimeout(() => {
        setNotificationModal(false);
      }, 15000);
    }
  }, [notificationModal]);

  const action = (val) => {
    setLicenseNumber(val);
  };
  const debounceSearch = debounce(action, 1000);
  return (
    <div
      className=""
      style={{
        // background: 'linear-gradient(to right, rgb(15, 23, 42), rgb(88, 28, 135), rgb(15, 23, 42))',
        background: 'linear-gradient(to right bottom, rgb(14, 165, 233), rgb(254, 215, 170), rgb(202, 138, 4))',
        width: '100%', // Adjust width as needed
        height: '100vh', // Adjust height as needed
      }}
    >
      <Modal open={notificationModal} footer={null} closable={false} centered width={700}>
        <div className="p-4 ">
          <div className="font-medium text-4xl text-primary uppercase my-3 ">Thank You for check in!</div>
          <div className="mt-5 text-2xl text-black font-medium ">
            Please drink enough water to produce sufficient required urine sample. If not ready please let the front
            desk know you want to wait. Thank you for your visit
          </div>
        </div>
      </Modal>

      <div className="">
        {selectClientType ? (
          <>
            <div className="flex justify-center">
              <img
                style={{
                  height: '100px',
                  width: 'auto',
                }}
                src={getTenant?.data?.logo}
              />
            </div>
            <h1
              style={{
                // textDecoration: 'underline',
                textTransform: 'uppercase',
                textAlign: 'center',
                fontSize: 20,
                marginTop: 30,
              }}
            >
              Donor Check In form for
              <span className="ml-2 underline font-medium">
                {selectClientType === 'network-client' ? 'Network Client' : 'In House Client'}
              </span>
            </h1>
            <div className="p-10">
              <Form
                form={form}
                onFinish={(values) => {
                  onSubmit(values);
                }}
              >
                <Row gutter={24} style={{ padding: '15px' }}>
                  <Col xs={24} sm={24} md={12} xl={12}>
                    <h4 className="text-lg font-medium">Driver License Number</h4>
                    <div
                      style={{
                        display: 'flex',
                      }}
                    >
                      <Form.Item
                        name="driverLicenseState"
                        initialValue=""
                        rules={[
                          {
                            required: true,
                            message: 'Please select Driver State',
                          },
                        ]}
                      >
                        <Select
                          onChange={(value) => {
                            setIssueState(value);
                          }}
                          className={`${style.customSelect} abcd`}
                          size="large"
                          dropdownMatchSelectWidth={false}
                          optionLabelProp="value"
                          style={{ borderRadius: '5px', minWidth: 100 }}
                        >
                          {useStates.map((i) => (
                            <Select.Option title={i.name} key={i.abbreviation} value={i.abbreviation}>
                              {i.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name="driverLicenseNumber"
                        initialValue=""
                        rules={[
                          {
                            required: true,
                            message: 'Please enter Driver License Number',
                          },
                        ]}
                      >
                        <Input
                          className={style['checkin-input-lg']}
                          size="large"
                          placeholder="Enter Driver License Number"
                          style={{ borderRadius: '5px', marginLeft: 10 }}
                          onBlur={(e) => {
                            debounceSearch(e?.target?.value);
                          }}
                          suffix={
                            loading ? (
                              <div className="border-gray-300 h-3 w-3 animate-spin rounded-full border-2 border-t-blue-600" />
                            ) : (
                              <></>
                            )
                          }
                        />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={12} xl={12}>
                    <h4 className="text-lg font-medium">Company Name</h4>

                    {selectClientType === 'network-client' ? (
                      <Form.Item
                        name="companyName"
                        initialValue=""
                        rules={[
                          {
                            required: true,
                            message: 'Please enter company name',
                          },
                        ]}
                      >
                        <Input
                          className={style['checkin-input-lg']}
                          size="large"
                          placeholder="Enter Company Name"
                          style={{ borderRadius: '5px' }}
                        />
                      </Form.Item>
                    ) : (
                      <>
                        <Form.Item
                          name="companyId"
                          initialValue=""
                          rules={[
                            {
                              required: true,
                              message: 'Please Select company',
                            },
                          ]}
                        >
                          <Select
                            className={`${style.customSelect} abcd`}
                            style={{ borderRadius: '5px' }}
                            size="large"
                            showSearch
                            placeholder="Choose Company"
                            optionFilterProp="children"
                            onChange={onChange}
                            onSearch={onSearch}
                            filterOption={(input, option) =>
                              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            options={companyData}
                          />
                        </Form.Item>
                      </>
                    )}
                  </Col>
                  <Col xs={24} sm={24} md={12} xl={12}>
                    <h4 className="text-lg font-medium">First name</h4>
                    <Form.Item
                      name="firstName"
                      initialValue=""
                      rules={[
                        {
                          required: true,
                          message: 'Please enter your first name',
                        },
                      ]}
                    >
                      <Input
                        className={style['checkin-input-lg']}
                        size="large"
                        placeholder="Enter First Name"
                        style={{ borderRadius: '5px' }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} xl={12}>
                    <h4 className="text-lg font-medium">Last name</h4>
                    <Form.Item
                      name="lastName"
                      initialValue=""
                      rules={[
                        {
                          required: true,
                          message: 'Please enter your last name',
                        },
                      ]}
                    >
                      <Input
                        className={style['checkin-input-lg']}
                        size="large"
                        placeholder="Enter Last Name"
                        style={{ borderRadius: '5px' }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} xl={12}>
                    <h4 className="text-lg font-medium">Email Address</h4>
                    <Form.Item
                      name="email"
                      initialValue=""
                      rules={[
                        {
                          required: true,
                          message: 'Please enter your email address',
                        },
                        // {
                        //   pattern: /^[^@!#$%^&*()_+=\[\]{};':"\\|,<>\/?][^!#$%^&*()_+=\[\]{};':"\\|,<>\/?]*$/,
                        //   message: 'Please enter valid email address',
                        // },
                      ]}
                    >
                      <Input
                        className={style['checkin-input-lg']}
                        size="large"
                        placeholder="Enter Email"
                        style={{ borderRadius: '5px' }}
                      />
                    </Form.Item>
                  </Col>
                  {selectClientType === 'house-client' ? (
                    <Col xs={24} sm={24} md={12} xl={12}>
                      <h4 className="text-lg font-medium">Date of birth</h4>
                      <Form.Item
                        name="dob"
                        initialValue=""
                        rules={[
                          {
                            required: true,
                            message: 'Please enter a valid date of birth',
                            pattern: regexPattern, // Validation pattern
                          },
                        ]}
                      >
                        <DateInput />
                      </Form.Item>
                    </Col>
                  ) : (
                    <></>
                  )}

                  <Col xs={24} sm={24} md={12} xl={12}>
                    <h4 className="text-lg font-medium">Phone Number</h4>
                    <Form.Item
                      name="phoneNumber"
                      initialValue=""
                      rules={[
                        {
                          required: true,
                          message: 'Please enter Phone Number',
                        },
                        {
                          pattern: regexPhone,
                          message: 'Please enter valid Phone Number',
                        },
                      ]}
                    >
                      {/* <Input
                        className={style['checkin-input-lg']} placeholder="Enter Phone Number" style={{ borderRadius: '5px' }} /> */}
                      <ReactInputMask mask="(999) 999-9999" maskChar="_">
                        {() => (
                          <Input
                            className={style['checkin-input-lg']}
                            size="large"
                            placeholder="Enter Phone Number"
                            style={{ borderRadius: '5px' }}
                            type="text"
                          />
                        )}
                      </ReactInputMask>
                    </Form.Item>
                  </Col>
                </Row>
                <div className="gap-3 flex justify-end mr-5">
                  <Button
                    size="large"
                    style={{ borderRadius: '5px' }}
                    onClick={() => {
                      form.resetFields();
                      setSelectClientType('');
                      setLicenseNumber('');
                      setDriverId('');
                    }}
                  >
                    <span className="text-black px-2">Cancel</span>
                  </Button>
                  <Button type="primary" size="large" style={{ borderRadius: '5px' }} onClick={() => form.submit()}>
                    <span className="text-white px-2">Submit</span>
                  </Button>
                </div>
              </Form>
            </div>
          </>
        ) : (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100vw',
                height: '100vh',
              }}
            >
              <div className="w-full">
                <div className="flex justify-center">
                  <img
                    style={{
                      height: '150px',
                      width: 'auto',
                    }}
                    src={getTenant?.data?.logo}
                  />
                </div>
                <h1
                  style={{
                    // textDecoration: 'underline',
                    textTransform: 'uppercase',
                    fontSize: 40,
                    textAlign: 'center',
                    marginTop: 30,
                    color: '#000',
                  }}
                >
                  {/* Donor Check In */}
                  Welcome
                </h1>
                <div
                  style={{
                    // textDecoration: 'underline',
                    fontSize: 20,
                    color: '#000',
                    fontFamily: ' monospace ',
                    textAlign: 'center',
                  }}
                >
                  Please select an option below to begin the process
                </div>
                <div className=" mt-4 mx-auto flex w-full  justify-around  items-center ">
                  <div
                    className="  justify-center font-medium cursor-pointer items-center flex  m-2"
                    style={{
                      fontSize: 35,
                      textTransform: 'uppercase',
                      color: '#000',
                      boxShadow: '2px 1px 2px 2px #9E9E9E3B',
                      width: 250,
                      height: 250,
                      borderRadius: 125,
                      background: 'radial-gradient(at right bottom, rgb(56, 189, 248), rgb(103, 232, 249))',
                    }}
                    onClick={() => {
                      setSelectClientType('network-client');
                    }}
                    onKeyDown={() => {}}
                    role="button"
                    tabIndex={0}
                  >
                    <span>
                      Network
                      <br />
                      Check-In
                    </span>
                  </div>
                  <div
                    className="  justify-center font-medium cursor-pointer items-center flex  m-2"
                    style={{
                      fontSize: 35,
                      textTransform: 'uppercase',
                      color: '#000',
                      width: 250,
                      height: 250,
                      borderRadius: 125,
                      boxShadow: '2px 1px 2px 2px #9E9E9E3B',
                      background: 'radial-gradient(at right bottom, rgb(251, 146, 60), rgb(251, 113, 133))',
                    }}
                    onKeyDown={() => {}}
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      setSelectClientType('house-client');
                    }}
                  >
                    In-House
                    <br />
                    Check-In
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
export default DonorCheckIn;
