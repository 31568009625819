const { defaults } = require('./defaults');

export const client = {
  createClient: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: '/client',
    },
  },
  driverTestCount: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v1,
      uri: '/client/testCount/:clientId',
    },
  },
  getAllClients: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v1,
      uri: '/client',
    },
  },
  expRandomCertList: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v1,
      uri: '/client/expRandomCert/list',
    },
  },

  getSingleClientDetails: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v1,
      uri: '/client/:id',
    },
  },
  updateClient: {
    v1: {
      ...defaults.methods.PUT,
      ...defaults.versions.v1,
      uri: '/client/:id',
    },
  },
  updateClientService: {
    v1: {
      ...defaults.methods.PUT,
      ...defaults.versions.v1,
      uri: '/client/service/:id',
    },
  },
  uploadClientContract: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: '/client/:id/contract',
    },
  },

  updateClientPricing: {
    v1: {
      ...defaults.methods.PUT,
      ...defaults.versions.v1,
      uri: '/client/pricing/:id',
    },
  },
  captureSignature: {
    v1: {
      ...defaults.methods.PUT,
      ...defaults.versions.v1,
      uri: '/client/capture/signature/:id',
    },
  },
  deleteClientDetails: {
    v1: {
      ...defaults.methods.DELETE,
      ...defaults.versions.v1,
      uri: '/client/:id',
    },
  },
  addNotes: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: '/client/create/note',
    },
  },
  deleteNotes: {
    v1: {
      ...defaults.methods.DELETE,
      ...defaults.versions.v1,
      uri: '/client/delete/note/:id',
    },
  },
  getAllCompanyNames: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v1,
      uri: '/client/selection',
    },
  },
  getDotDetails: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v1,
      uri: '/client/dot',
    },
  },
  withNoPreEmployementTest: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v1,
      uri: '/client/drivers/withNoPreEmploymentTest',
    },
  },
  updateRandomCertificate: {
    v1: {
      ...defaults.methods.PUT,
      ...defaults.versions.v1,
      uri: '/client/:id/update/random',
    },
  },
  updateExpirationDate: {
    v1: {
      ...defaults.methods.PUT,
      ...defaults.versions.v1,
      uri: '/client/:id/random/expiration',
    },
  },
  deleteDriversList: {
    v1: {
      ...defaults.methods.DELETE,
      ...defaults.versions.v1,
      uri: '/client/active/random',
    },
  },
};
