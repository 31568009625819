const { defaults } = require('./defaults');

export const donorEndPoints = {
  createDriver: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: '/donor/driver',
    },
  },
  createDonor: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: '/donor',
    },
  },
  getDonors: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v1,
      uri: '/donor',
    },
  },
  exportDonor: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v1,
      uri: '/donor/export',
    },
  },
  updateDonor: {
    v1: {
      ...defaults.methods.PUT,
      ...defaults.versions.v1,
      uri: '/donor/:id',
    },
  },
  getSingleDonorDetails: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v1,
      uri: '/donor/:id',
    },
  },
  updateBasicDetails: {
    v1: {
      ...defaults.methods.PUT,
      ...defaults.versions.v1,
      uri: '/donor/basic-details/:id',
    },
  },
  driverDetailFromLicenseNumber: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v1,
      uri: '/donor/driver/:licenseNumber/:issueState',
    },
  },
};
