import { callApi } from '../utility/apiUtils';
import { authEndpoints } from '../endpoints/auth';

export const getTenantData = () => callApi({ uriEndPoint: authEndpoints.getTenant.v1 });

export const getActivity = () => callApi({ uriEndPoint: authEndpoints.getActivity.v1 });
export const getPreferences = () => callApi({ uriEndPoint: authEndpoints.getPreference.v1 });

export const updatePreference = (body) => callApi({ uriEndPoint: authEndpoints.updatePreference.v1, body });
export const forgotPassword = ({ body }) => callApi({ uriEndPoint: authEndpoints.forgotPassword.v1, body });
export const verifyOtp = ({ body, pathParams }) =>
  callApi({ uriEndPoint: authEndpoints.verifyOtp.v1, body, pathParams });
export const resetPassword = ({ pathParams }) => callApi({ uriEndPoint: authEndpoints.resetPassword.v1, pathParams });
