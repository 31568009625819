import React, { useLayoutEffect } from 'react';
// import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { TopMenuStyle } from './Style';

function TopMenu() {
  // const path = '/';
  const { currentUser } = useSelector((state) => {
    return {
      currentUser: state.currentUser.data,
    };
  });

  useLayoutEffect(() => {
    const active = document.querySelector('.hexadash-top-menu a.active');
    const activeDefault = () => {
      const megaMenu = active.closest('.megaMenu-wrapper');
      const hasSubMenuLeft = active.closest('.has-subMenu-left');
      if (!megaMenu) {
        active.closest('ul').previousSibling.classList.add('active');
        if (hasSubMenuLeft) hasSubMenuLeft.closest('ul').previousSibling.classList.add('active');
      } else {
        active.closest('.megaMenu-wrapper').previousSibling.classList.add('active');
      }
    };
    window.addEventListener('load', active && activeDefault);
    return () => window.removeEventListener('load', activeDefault);
  }, []);
  return (
    <TopMenuStyle>
      <div className="hexadash-top-menu ltr:pl-[20px] rtl:pr-[20px] xl:ltr:pl-[10px] xl:rtl:pr-[10px]">
        <ul>
          <li className="">
            <Link to="/" className="parent">
              Dashboard
            </Link>
          </li>

          <li className="">
            <Link to="/driver" className="parent">
              Driver
            </Link>
          </li>

          <li className="">
            <Link to="/driver/test/result" className="parent ">
              Test result
            </Link>
          </li>
          <li className="">
            <Link to="/transactions" className="parent">
              Transactions
            </Link>
          </li>

          {currentUser?.randomCertificate && (
            <li className="">
              <Link to="/randomCertificate" className="parent">
                Random Certificate
              </Link>
            </li>
          )}
          {currentUser?.pool?.type === 'stand-alone' && (
            <li className="">
              <Link to="/pool" className="parent">
                Random Pool Data
              </Link>
            </li>
          )}
          <li className="">
            <Link to="/activity" className="parent">
              Activity Logs
            </Link>
          </li>
          <li className="">
            <Link to="/ordered-test" className="parent">
              Tests Ordered
            </Link>
          </li>
        </ul>
      </div>
    </TopMenuStyle>
  );
}

export default TopMenu;
