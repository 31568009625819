import React from 'react';
import PropTypes from 'prop-types';

export const regexPattern = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/;
function DateInput({ value, onChange, onBlur }) {
  console.log(value, 'value');
  return (
    <div className="flex flex-col w-full mt-[1px] ">
      <input
        type="text"
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        placeholder="MM/DD/YYYY"
        className="p-3 px-4 placeholder-gray-400 hover:rounded-sm placeholder:text-sm border hover:border-primary rounded-sm shadow-sm text-base transition-colors duration-300 ease-in-out"
      />
    </div>
  );
}

DateInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};
export default DateInput;
