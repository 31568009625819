/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/prop-types */
import { Row } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';

function RandomCertificate() {
  // const [modal, setModal] = useState('');

  const currentUser = useSelector((state) => state.currentUser.data);
  return (
    <>
      <Row className="bg-white p-5">
        <div className="flex  items-center w-full">
          <div className="text-2xl font-semibold flex-1 ">Random Certificate</div>
        </div>

        {currentUser?.randomCertificate ? (
          <div className="mt-5 w-full">
            <div className="p-5 ">
              <iframe src={currentUser?.randomCertificate} title="Random Certificate" width="100%" height="800px" />
            </div>
            {/* <Button
              type="primary"
              size="large"
              onClick={() => {
                setModal(currentUser?.randomCertificate);
              }}
            >
              View Certificate
            </Button> */}
          </div>
        ) : (
          <></>
        )}
        {/* <Modal
          width={1200}
          title="Random Certificate"
          open={modal}
          onOk={() => setModal('')}
          onCancel={() => setModal('')}
          footer={null}
        >
          <div className="p-5 ">
            <iframe src={modal} title="Random Certificate" width="100%" height="800px" />
          </div>
        </Modal> */}
      </Row>
    </>
  );
}

export default RandomCertificate;
