const { defaults } = require('./defaults');

export const driver = {
  createDriver: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: '/driver',
    },
  },
  createDriverBulk: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: '/driver/create-bulk',
    },
  },
  getAllDrivers: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v1,
      uri: '/driver',
    },
  },
  preEmploymentDrivers: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v1,
      uri: '/driver/pre-employment',
    },
  },
  getRandomDrivers: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v1,
      uri: '/random/driver',
    },
  },
  getPoolRandomDrivers: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v1,
      uri: '/random/random-driver/:poolId',
    },
  },
  getPoolBatDrivers: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v1,
      uri: '/random/bat-driver/:poolId',
    },
  },
  // to display the alternate drivers in the pool
  getPoolAlternateDrivers: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v1,
      uri: '/random/alternate-driver/:poolId',
    },
  },
  // when random or bat drive ris not available , then we will pick up the alternate drivers
  // then this api will be called
  getPoolAlternateDriversList: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v1,
      uri: '/random/alternate/:poolId',
    },
  },
  getPoolSelectedDrivers: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v1,
      uri: '/random/selected-driver/:poolId',
    },
  },
  getAllSelectedDrivers: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v1,
      uri: '/random/selected-driver/basic/:poolId',
    },
  },
  getSingleDriverDetails: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v1,
      uri: '/driver/:id',
    },
  },
  getDriverDetails: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v1,
      uri: '/driver/single/:id',
    },
  },

  updateDriver: {
    v1: {
      ...defaults.methods.PUT,
      ...defaults.versions.v1,
      uri: '/driver/:id',
    },
  },
  hireDriver: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: '/driver/:id/hire',
    },
  },
  updateDriverStage: {
    v1: {
      ...defaults.methods.PUT,
      ...defaults.versions.v1,
      uri: '/driver/:id/stage',
    },
  },
  updatePreEmployment: {
    v1: {
      ...defaults.methods.PUT,
      ...defaults.versions.v1,
      uri: '/driver/:id/pre-employment',
    },
  },
  updateDriverStatus: {
    v1: {
      ...defaults.methods.PUT,
      ...defaults.versions.v1,
      uri: '/driver/:id/status',
    },
  },
  updateDriverClient: {
    v1: {
      ...defaults.methods.PUT,
      ...defaults.versions.v1,
      uri: '/driver/:id/client',
    },
  },
  sendNotification: {
    v1: {
      ...defaults.methods.PUT,
      ...defaults.versions.v1,
      uri: '/random/sendNotification',
    },
  },
  deleteDriverDetails: {
    v1: {
      ...defaults.methods.DELETE,
      ...defaults.versions.v1,
      uri: '/driver/:id',
    },
  },
  createImage: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: '/upload/image',
    },
  },
  addToRandomList: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: '/driver/random/pull',
    },
  },
  createRandomDrivers: {
    v1: {
      ...defaults.methods.PUT,
      ...defaults.versions.v1,
      uri: '/random/driver/:poolId',
    },
  },
  selectedDrivers: {
    v1: {
      ...defaults.methods.PUT,
      ...defaults.versions.v1,
      uri: '/pool/selected-driver/:poolId',
    },
  },
  alternateDrivers: {
    v1: {
      ...defaults.methods.PUT,
      ...defaults.versions.v1,
      uri: '/pool/alternate-drivers/:poolId',
    },
  },
  removeFromRandomList: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: '/driver/random/push',
    },
  },
  setQuarter: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: '/driver/create/randomTest',
    },
  },
  createDriverDrugTest: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: '/driver/create/driverTest',
    },
  },
  getOrdersTestCaseList: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v1,
      uri: '/test-cases/order',
    },
  },
  orderTestCase: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: '/test-cases/order',
    },
  },
  createTestCase: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: '/test-cases/create',
    },
  },
  updateTestCases: {
    v1: {
      ...defaults.methods.PUT,
      ...defaults.versions.v1,
      uri: '/test-cases/:id',
    },
  },
  addFollowUpTest: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: '/driver/create/followup/test',
    },
  },
  addQuery: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: '/driver/create/drugAndAlcoholTest',
    },
  },
  uploadPreEmploymentResult: {
    v1: {
      ...defaults.methods.PUT,
      ...defaults.versions.v1,
      uri: '/driver/preEmployment/result/:testId',
    },
  },
  uploadRandomResult: {
    v1: {
      ...defaults.methods.PUT,
      ...defaults.versions.v1,
      uri: '/driver/upload/randomTest/:testId',
    },
  },
  uploadPreEmpTest: {
    v1: {
      ...defaults.methods.PUT,
      ...defaults.versions.v1,
      uri: '/driver/upload/preEmp/:id',
    },
  },
  driverListStats: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v1,
      uri: '/driver/count/by/filter',
    },
  },
  testResult: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v1,
      uri: '/test-cases/list',
    },
  },
  dashboardTestingList: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v1,
      uri: '/test-cases/dashboard-pending-list',
    },
  },
  uploadTestResult: {
    v1: {
      ...defaults.methods.PUT,
      ...defaults.versions.v1,
      uri: '/test-cases/upload-test/:id',
    },
  },
  deleteDrugTest: {
    v1: {
      ...defaults.methods.DELETE,
      ...defaults.versions.v1,
      uri: '/test-cases/:id',
    },
  },
  createNotes: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: '/driver/note',
    },
  },
  deleteNotes: {
    v1: {
      ...defaults.methods.DELETE,
      ...defaults.versions.v1,
      uri: '/driver/note/:id',
    },
  },
  editNotes: {
    v1: {
      ...defaults.methods.PUT,
      ...defaults.versions.v1,
      uri: '/driver/note/:id',
    },
  },
  deleteDriversList: {
    v1: {
      ...defaults.methods.DELETE,
      ...defaults.versions.v1,
      uri: '/driver/bulk',
    },
  },
};
